.navbar {
    margin: 0 auto;
    padding: 2rem 5vw;
    background-color: var(--primary-white);
}

.navbar-brand .nav-item {
    -webkit-transition: var(--transition-time);
    -moz-transition: var(--transition-time);
    -o-transition: var(--transition-time);
    transition: var(--transition-time);
}

.navbar-brand, .nav-item{
    color: var(--text-dark);
    font-weight: var(--font-bold);
    font-size: 1.2em;
}

.navbar-brand:hover, .nav-link:hover {
    color: var(--primary-blue);
}

.nav-item {
    padding-left: 20px;
    font-size: 1em;
}

.nav-link {
    color: var(--text-dark)
}


.nav-link.active {
    background-color: var(--primary-cream) !important;
    border-bottom-color: var(--primary-cream);
    color: var(--primary-blue) !important;
}


.navbar-toggler {
    border: none;
}

.smart-scroll {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 100;
}

.scrolled-down {
    transform: translateY(-100%);
    transition: all 0.3s ease-in-out;
}

.scrolled-up {
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
}

.ml-auto {
    margin-left: auto !important;
}