.sidebar {
    position: fixed;
    padding: 5em 0;
    color: var(--text-light);
    text-align: left;
}

.anchor {
    display: block;
    color: var(--text-light);
    line-height: 2;
}

.anchor:hover, .sidebar-highlight {
    color: var(--primary-blue)
}


@media only screen and (max-width: 992px) {
    .sidebar {
        display: none;
    }
    
}